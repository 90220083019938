import React, {
  Component,
  Fragment,
  Suspense,
  useState,
  useEffect,
} from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import ReactJoyride from "react-joyride";
import { API } from "./services/api-services";
import { useSelector, useDispatch } from "react-redux";
import Authentication from "./Core/Authentication";
import Routes from "./Core/Routes";
import Loader from "./Core/Loader/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { decrypt } from "./utils/other";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LaptopStepsData from "../src/LaptopStepsData";
import MobileStepsData from "../src/MobileStepsData";
import * as Sentry from "@sentry/react";
import { Typography } from "@material-ui/core";
import { reducer_insertData } from "./Redux/Actions/studentInfo";
import male_default from "./assets/images/male_default.png";
import female_default from "./assets/images/female_default.png";
import { Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { WhatsappIcon } from "react-share";
import Profile from "./Components/StudentDashboard/Profiles/Profile";
import { toast } from "react-toastify";
import ViewRecording from "./Components/Resources/Recordings/ViewRecording";
import Reschedule from "./Components/AutoReschedule/Reschedule";
import { getUser } from "./utils/auth";
import ChatWidget from "./Components/ChatWidget";
import NativeComponent from "./Components/NativeComponents";
import RaiseRequestModal from "./Components/ParentDashboard/RaiseRequestModal";

//Lazy Imports
const DesktopHeader = React.lazy(() =>
  import("./Components/HeaderComponent/DesktopHeader")
);
const Login = React.lazy(() => import("./Components/Login/Login"));
const HeaderComponent = React.lazy(() =>
  import("./Components/HeaderComponent/HeaderComponent")
);
const Dashboard = React.lazy(() =>
  import("./Components/StudentDashboard/StudentDashboard")
);
const ParentDashboard = React.lazy(() =>
  import("./Components/ParentDashboard/ParentDashboard")
);
const StudentSummary = React.lazy(() =>
  import("./Components/ParentDashboard/StudentSummary")
);
const BookDemo = React.lazy(() =>
  import("./Components/ParentDashboard/BookDemo")
);
const ViewSchedule = React.lazy(() =>
  import("./Components/StudentDashboard/ViewSchedule/ViewSchedule")
);
const Referrals = React.lazy(() => import("./Components/Referral/Referral"));
const Index = React.lazy(() => import("./Components/NewReferalFlow/Index"));
const PtmFeedBack = React.lazy(() =>
  import("./Components/TeacherFeedbackForm/FeedBackForm")
);
const Referal = React.lazy(() => import("./Components/NewReferalFlow/Referal"));
const Resources = React.lazy(() => import("./Components/Resources/Resources"));
const Subscriptions = React.lazy(() =>
  import("./Components/Subscriptions/Subscriptions")
);
const Community = React.lazy(() => import("./Components/Community/Community"));
const ShowAllProjects = React.lazy(() =>
  import("./Components/Community/ShowAllProjects/ShowAllProjects")
);
const EditProfile = React.lazy(() =>
  import("./Components/EditProfile/EditProfile")
);
const ZoomMeet = React.lazy(() => import("./Components/ZoomMeet/ZoomMeet"));
const LeaveZoom = React.lazy(() => import("./Components/ZoomMeet/LeaveZoom"));
const AutoReschedule = React.lazy(() =>
  import("./Components/AutoReschedule/Reschedule")
);
const CancelAndReschedule = React.lazy(() =>
  import("./Components/CancelAndReschedule/Index")
);
const ChangeBatchSchedule = React.lazy(() =>
  import("./Components/ChangeBatchSchedule/Index")
);

const StudentCommunity = React.lazy(() =>
  import("./Components/Community/studentCommunity")
);
const Error = React.lazy(() => import("./Components/error"));
const PreviewAlchemyFile = React.lazy(() =>
  import("./Components/Resources/Alchemy/PreviewAlchemyFile")
);

const WeeklyReport = React.lazy(() => import("./Components/WeeklyReports"));

const Notifications = React.lazy(() => import("./Components/Notifications"));

const useStyles = makeStyles((theme) => ({
  bigScreen: {
    display: "flex",
    overflowY: "hidden",
  },
  mobileScreen: {
    display: "block",
  },
  loadData: {
    width: "90%",
    display: "block",
  },
}));
toast.configure();
const ProtectedRoute = () => {
  const lsData = decrypt(localStorage.getItem("user"));
  const [laptopSteps, setLaptopSteps] = useState(LaptopStepsData);
  const [mobileSteps, setMobileSteps] = useState(MobileStepsData);

  const routes = [
    {
      path: "/dashboard",
      component: Dashboard,
      exact: true,
      allow: true,
    },
    {
      path: "/parent-dashboard",
      component: ParentDashboard,
      exact: true,
      allow: true,
    },
    {
      path: "/student-summary",
      component: StudentSummary,
      exact: true,
      allow: true,
    },
    {
      path: "/book-a-demo",
      component: BookDemo,
      exact: true,
      allow: true,
    },
    {
      path: "/referrals",
      component: Referrals,
      exact: true,
      allow: true,
    },
    {
      path: "/resources",
      component: Resources,
      exact: true,
      allow: true,
    },
    {
      path: "/edit-profile",
      component: EditProfile,
      exact: true,
      allow: true,
    },
    {
      path: "/subscriptions",
      component: Subscriptions,
      exact: true,
      allow: lsData?.source === "Codeyoung" ? true : false,
    },
    {
      path: "/community",
      component: Community,
      exact: true,
      allow: true,
    },
    {
      path: "/community/showAllProject/:apiId",
      component: ShowAllProjects,
      exact: false,
      allow: true,
    },
    {
      path: "/notifications",
      component: Notifications,
      exact: true,
      allow: true,
    },
    {
      path: "/addRescheduleClass",
      component: Reschedule,
      exact: false,
      allow: true,
    },
  ];
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();

  const user = getUser();
  const [index, setIndex] = useState(0);
  const [skip, setSkip] = useState(false);
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [closeTour, setCloseTour] = useState(true);
  const productTourStatus = localStorage.getItem("productTourStatus");
  const [onBoardingComplete, setOnBoardingComplete] = useState(false);
  const dispatch = useDispatch();
  const studentData = useSelector(
    (state) => state.studentInfoReducer.studentInfoData
  );
  const [showProfiles, setShowProfiles] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errFetchUsers, setErrFetchUsers] = useState(false);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      let local = decrypt(localStorage.getItem("user"));
      const obj = {
        studentName: local?.studentName,
        profileImage: local?.profilePicUrl,
        initial: local?.studentName?.charAt(0),
        source: local?.source,
      };
      dispatch(dispatch(reducer_insertData(obj)));
    }
  }, [dispatch]);
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("profileSwitched")) == true) {
      localStorage.setItem("profileSwitched", false);
      if (!window.ReactNativeWebView) {
        toast.success("Profile is switched successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }, []);
  useEffect(() => {
    if (!user?.hasOwnProperty("parentId")) {
      setLoading(true);
      API.get("/fetchProfiles")
        .then((res) => {
          setProfileData(res.data.profiles);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setErrFetchUsers(true);
          setLoading(false);
        });
    }
  }, []);
  const handleJoyrideCallback = (data) => {
    const { action, lifecycle, status, index } = data;

    if (action === "skip" && lifecycle === "complete" && status === "skipped") {
      setSkip(true);
      setOnBoardingComplete(true);
    } else if (
      action === "next" &&
      lifecycle === "complete" &&
      status === "finished"
    ) {
      setOnBoardingComplete(true);
    }

    if (action === "close") {
      setSkip(true);
      setCloseTour(false);
    }

    if (index >= 0) {
      if (
        (action === "next" || action === "prev") &&
        lifecycle === "complete" &&
        status === "running"
      ) {
        setSkip(false);
        setIndex(index);
      }
    }
  };

  return (
    <div style={{ background: "#F7FBFB" }}>
      {showProfiles && profileData?.length > 1 && (
        <Profile
          setShowProfiles={setShowProfiles}
          showProfiles={showProfiles}
          profileData={profileData}
          errFetchUsers={errFetchUsers}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {productTourStatus === "false" && (
        <>
          {mobileDevice ? (
            closeTour && (
              <ReactJoyride
                steps={mobileSteps.steps}
                continuous
                showProgress
                showSkipButton
                disableCloseOnEsc
                disableOverlayClose
                scrollToFirstStep
                disableBeacon={true}
                scrollOffset={250}
                skipBeacon={true}
                callback={handleJoyrideCallback}
                styles={{
                  options: {
                    // modal arrow and background color
                    arrowColor: "#FEF5EB",
                    backgroundColor: "#FEF5EB",
                    // page overlay color
                    overlayColor: "rgb(47 46 46)",
                    //button color
                    primaryColor: "#FF712D",
                    //text color
                    textColor: "#333",

                    //width of modal
                    width: 500,
                    //zindex of modal
                    zIndex: 1000,
                  },
                }}
              />
            )
          ) : (
            <ReactJoyride
              steps={laptopSteps.steps}
              continuous
              showProgress
              showSkipButton
              disableCloseOnEsc
              disableOverlayClose
              scrollToFirstStep
              disableBeacon={true}
              scrollOffset={90}
              callback={handleJoyrideCallback}
              styles={{
                options: {
                  // modal arrow and background color
                  arrowColor: "#FEF5EB",
                  backgroundColor: "#FEF5EB",
                  // page overlay color
                  overlayColor: "rgb(47 46 46)",
                  //button color
                  primaryColor: "#FF712D",
                  //text color
                  textColor: "#333",
                  //width of modal
                  width: 500,
                  //zindex of modal
                  zIndex: 1000,
                },
              }}
            />
          )}
        </>
      )}
      <div className={mobileDevice ? classes.mobileScreen : classes.bigScreen}>
        {!location.pathname.includes("chatwithus") && (
          <HeaderComponent
            className={!mobileDevice ? classes.sideBar : ""}
            index={index}
            mobileDevice={mobileDevice}
            skip={skip}
            productTourStatus={productTourStatus}
            setShowProfiles={setShowProfiles}
            showSwitchProfile={profileData?.length > 1}
          />
        )}

        <div className={!mobileDevice ? classes.loadData : ""}>
          {!mobileDevice && (
            <DesktopHeader
              setShowProfiles={setShowProfiles}
              showProfiles={showProfiles}
              showSwitchProfile={profileData?.length > 1}
            />
          )}
          <div>
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route path={"/dashboard"}>
                  <Dashboard
                    onBoardingComplete={onBoardingComplete}
                    mobileDevice={mobileDevice}
                  />
                </Route>
                <Route path={"/chatwithus"}>
                  <ChatWidget />
                </Route>
                {window.ReactNativeWebView && (
                  <Route path={"/raise-request"}>
                    <RaiseRequestModal />
                  </Route>
                )}
                {window.ReactNativeWebView && (
                  <Route path={"/parent-dashboard"}>
                    <ParentDashboard />
                  </Route>
                )}
                {window.ReactNativeWebView && (
                  <Route path={"/student-summary"}>
                    <StudentSummary />
                  </Route>
                )}
                {window.ReactNativeWebView && (
                  <Route path={"/book-a-demo"}>
                    <BookDemo />
                  </Route>
                )}
                <Route path="/referrals">
                  <Referrals />
                </Route>
                <Route path={"/resources"}>
                  <Resources />
                </Route>
                <Route path="/edit-profile">
                  <EditProfile />
                </Route>
                <Route path="/subscriptions">
                  <Subscriptions />
                </Route>
                <Route path="/community/showAllProject/:apiId">
                  <ShowAllProjects />
                </Route>
                <Route path={"/community"}>
                  <Community />
                </Route>
                <Route path={"/viewSchedule"}>
                  <ViewSchedule />
                </Route>
                <Route path="/notifications">
                  <Notifications />
                </Route>
                <Route path={"/addRescheduleClass"}>
                  <Reschedule />
                </Route>
                <Route path={"/weeklyRecap/:uuid"}>
                  <WeeklyReport />
                </Route>
                <Redirect to="/dashboard" />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/" component={Login} exact />
              <Route path="/class/:meetingId/:uId" component={ZoomMeet} exact />
              <Route path="/leaveZoom" component={LeaveZoom} exact />
              <Route path="/404" component={Error} exact />
              <Route path={"/viewRecording"} component={ViewRecording} exact />
              <Route
                path="/autoReschedule/:scheduleId/:studentId"
                component={AutoReschedule}
                exact
              />
              <Route
                path={"/cancel/reschedule/:studentId"}
                component={CancelAndReschedule}
                exact
              />
              <Route path={"/achievements"} component={Index} exact />
              <Route path={"/newReferral"} component={Referal} exact />
              <Route path={"/feedback/:token"} component={PtmFeedBack} exact />
              <Route
                path={"/batch/schedule/:token"}
                component={ChangeBatchSchedule}
                exact
              />
              <Route
                path="/previewFile/:id"
                component={PreviewAlchemyFile}
                exact
              />
              <Route path="/weeklyRecap/:uuid" component={WeeklyReport} exact />

              <Route path="/:type/:id" component={StudentCommunity} exact />
              {window.ReactNativeWebView && (
                <Route path={"/native"} component={NativeComponent} exact />
              )}
              <Authentication>
                <ProtectedRoute />
              </Authentication>
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </Fragment>
    );
  }
}

export default Sentry.withProfiler(App);
