import { useEffect } from "react";
import { encrypt } from "../../utils/other";

function NativeComponent() {
  useEffect(() => {
    const handleMessage = async (event) => {
      const data = event.data;
      console.log(event.data, "event data");

      // Handle the received data in your web app
      if (data?.authToken && data?.refreshToken) {
        const authObj = {
          ACCESS_TOKEN: data?.authToken,
          REFRESH_TOKEN: data?.refreshToken,
        };
        const encryptedUserData = encrypt(authObj);
        await localStorage.setItem("user", encryptedUserData);

        if (data?.isReschedule) {
          await localStorage.setItem("isReschedule", data?.isReschedule);
        }

        if (data?.userRole) {
          await localStorage.setItem("userRole", data?.userRole);
        }
        window.location.href = data.url;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  });
  return null;
}

export default NativeComponent;
