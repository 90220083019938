import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@mui/material/DialogContent";
import update from "immutability-helper";
import { toast } from "react-toastify";
import { useStyles } from "./parent-dashboard";
import { useState } from "react";
import { API_EPICENTER } from "../../services/api-services";
import { Spinner } from "react-bootstrap";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function RaiseRequestModal({ open, handleClose, data }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    studentId: "",
    courseCategory: "",
    studentIssue: "",
    details: "",
  });
  const [errorLog, setErrorLog] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const courses = ["Coding", "Math", "Science", "English"];
  const category = [
    "I wish to change my regular schedule for classes.",
    "I want to provide feedback for my mentor Please provide student progress report (SPR).",
    "Please arrange parent teacher meeting (PTM).",
    "I've not received my referral bonus even after 45 days of referee enrolling.",
    "I'm facing technical issue.",
    "Upgrade my course from group to 1-on-1.",
    "Please change my mentor",
    "I wish to speak with Codeyoung representative",
  ];

  const onChangeHandler = (e, type) => {
    setSubmitError(false);
    setErrorLog(false);
    switch (type) {
      case "student":
        const updatedLeadName = update(formData, {
          studentId: { $set: e.target.value },
        });
        return setFormData(updatedLeadName);
      case "course":
        const updateCourse = update(formData, {
          courseCategory: { $set: e.target.value },
        });
        return setFormData(updateCourse);
      case "category":
        const updateCategory = update(formData, {
          studentIssue: { $set: e.target.value },
        });
        return setFormData(updateCategory);
      case "other":
        const updateotherDetails = update(formData, {
          details: { $set: e.target.value },
        });
        return setFormData(updateotherDetails);
      default:
        return;
    }
  };

  const handleSave = () => {
    setSubmitError(false);
    setErrorLog(false);
    if (
      formData.studentId === "" ||
      formData.courseCategory === "" ||
      formData.studentIssue === ""
    ) {
      return setErrorLog(true);
    }
    setLoading(true);
    API_EPICENTER.post("/ticketGeneration", {
      studentId: formData.studentId,
      text: formData.details,
      courseCategory: formData.courseCategory,
      studentIssue: formData.studentIssue,
    })
      .then((res) => {
        toast.success("Query Submitted", {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
        handleClose();
      })
      .catch((e) => {
        toast.error(e?.response?.data?.msg, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
        setSubmitError(true);
        console.error("Error while saving details", e);
      });
  };
  return (
    <React.Fragment>
      <Box style={{ padding: "32px 16px" }}>
        {/* <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      > */}
        {/* <div
        className={`${classes.textRight} ${classes.width100}`}
        style={{ padding: "10px" }}
      >
        <CloseOutlinedIcon onClick={handleClose} />
      </div> */}
        <Typography
          style={{ padding: "0px 10px" }}
          className={`${classes.font18} ${classes.font800} ${classes.textAlignCenter} ${classes.headingClr}`}
        >
          Please provide detailed information below. We would like to serve you
          better.
        </Typography>
        {/* <DialogContent> */}
        <Box
          style={{ minHeight: "319px" }}
          className={`${classes.flex_col} ${classes.alignContentCenter} ${classes.justifyAround} ${classes.width100}`}
        >
          <TextField
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: "360px",
                  },
                },
              },
            }}
            select
            variant="outlined"
            onChange={(e) => onChangeHandler(e, "student")}
            value={formData.studentId}
            fullWidth
            size="small"
            className={classes.inputField}
            label="Select Student"
            required
          >
            {data?.students?.map((student, index) => {
              return (
                <MenuItem key={index} value={student.studentId}>
                  {student.studentName}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: "360px",
                  },
                },
              },
            }}
            select
            variant="outlined"
            onChange={(e) => onChangeHandler(e, "course")}
            value={formData.courseCategory}
            fullWidth
            size="small"
            className={`${classes.inputField} ${classes.width100}`}
            label="Select Course"
            required
          >
            {courses?.map((course, index) => {
              return (
                <MenuItem key={index} value={course}>
                  {course}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: "360px",
                    maxWidth: "260px",
                  },
                },
              },
            }}
            select
            variant="outlined"
            onChange={(e) => onChangeHandler(e, "category")}
            value={formData.studentIssue}
            fullWidth
            size="small"
            className={classes.inputField}
            label="Select Category"
          >
            {category.map((dataa, index) => {
              return (
                <MenuItem
                  key={index}
                  value={dataa}
                  style={{ whiteSpace: "pre-line" }}
                >
                  {dataa}
                </MenuItem>
              );
            })}
          </TextField>
          <Grid className={`${classes.flex_col} ${classes.textAreaBox}`}>
            <Typography
              className={`${classes.font14} ${classes.font500} ${classes.headingClr}`}
            >
              Please share more details
            </Typography>
            <TextareaAutosize
              rowsMax={10}
              value={formData.details}
              className={`${classes.inputField} ${classes.width100}`}
              onChange={(e) => onChangeHandler(e, "other")}
              fullWidth
              style={{
                height: 90,
                border: "1px solid #B8B8B8",
                marginTop: "10px",
                padding: "10px",
                color: "#B8B8B8",
              }}
              placeholder="Enter your text here..."
            />
          </Grid>
        </Box>
        {/* </DialogContent> */}
        {/* <DialogActions> */}
        <Box
          style={{ padding: "0px 12px" }}
          className={`${classes.flex_row} ${classes.justifyCenter} ${classes.width100} pb-1`}
        >
          <Button
            disabled={loading}
            className={`${classes.saveBtn} ${classes.width100}`}
            onClick={handleSave}
          >
            {loading ? (
              <Spinner animation="border" variant="grey" />
            ) : (
              "Submit feedback"
            )}
          </Button>
        </Box>
        {/* </DialogActions> */}
        {errorLog ? (
          <Grid
            style={{
              color: "red",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "10px",
              paddingBottom: "15px",
            }}
          >
            Please fill all required details
          </Grid>
        ) : (
          submitError && (
            <Grid
              style={{
                color: "red",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "10px",
                paddingBottom: "15px",
              }}
            >
              Sorry! something went Wrong <br />
              Please try again in some time
            </Grid>
          )
        )}
        {/* </Dialog> */}
      </Box>
    </React.Fragment>
  );
}
