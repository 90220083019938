import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Roboto",
    height: "calc(100vh - 56px)",
    overflow: "auto",
    position: "relative",
  },
  summaryRoot: {
    fontFamily: "Roboto",
    // height: "calc(100vh - 130px)",
    overflow: "auto",
  },
  gobackStickyWrapper: {
    position: "sticky",
    top: 0,
    width: "100%",
    background: "#f7fbfb",
    zIndex: 100,
    padding: "10px",
  },
  goBackStickyBtn: {
    textTransform: "none",
  },
  font22: {
    fontSize: "22px",
  },
  font20: {
    fontSize: "20px",
  },
  font18: {
    fontSize: "16px",
  },
  font14: {
    fontSize: "14px",
  },
  font12: {
    fontSize: "12px",
  },
  font800: {
    fontWeight: 800,
  },
  font700: {
    fontWeight: 700,
  },
  font500: {
    fontWeight: 500,
  },
  font400: {
    fontWeight: 400,
  },
  headingClr: {
    color: "#446C6C",
  },
  subHeadingClr: {
    color: "#5B8989",
  },
  fadedClr: {
    color: "#95B2B2",
  },
  flex_row: {
    display: "flex",
  },
  flex_col: {
    display: "flex",
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyAround: {
    justifyContent: "space-around",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  alignContentCenter: {
    alignItems: "center",
    alignContent: "center",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  alignContentleft: {
    alignItems: "left",
    alignContent: "left",
  },
  alignContentTop: {
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  justifyRight: {
    justifyContent: "right",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textAlignleft: {
    textAlign: "left",
  },
  overviewClassCard: {
    boxShadow: "2px 1px 4px 0px #446C6C1F",
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    borderRadius: "6px",
  },
  innerdivider: {
    marginTop: "15px",
  },
  divider: {
    border: "1px solid #E6EFEF",
    marginTop: "30px",
  },
  dividerVertical: {
    border: "1px solid #E6EFEF",
    width: "10px",
    height: "100%",
  },
  studentCard: {
    maxWidth: "166px",
    minHeight: "178px",
    borderRadius: "12px",
    border: "1px solid #E6EFEF",
    background: "#FFF",
    padding: "0px 10px",
    position: "relative",
  },
  studentImg: {
    height: "58px",
    width: "58px",
    borderRadius: "50%",
  },
  upcomingClassCard: {
    borderRadius: "12px",
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    border: "1px solid #E6EFEF",
    padding: "15px",
    marginTop: "20px",
    position: "relative",
  },
  dateBox: {
    background: "#D9E8E8",
    borderRadius: "8px",
    padding: "10px 0px",
  },
  helpCard: {
    borderRadius: "12px",
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    border: "1px solid #E6EFEF",
    padding: "20px 0px",
    height: "218px",
  },
  helpBtn: {
    border: "1px solid #FF712D",
    color: "#FF712D",
    padding: "8px 16px 8px 16px",
    borderRadius: "12px",
    width: "max-content",
  },
  freeDemoBtn: {
    width: "max-content",
    textTransform: "none",
    background: "#FF712D",
    borderRadius: "12px",
    padding: "7px 15px",
    color: "#FFF",
  },
  differentCrsCard: {
    height: "266px",
    width: "100%",
    borderRadius: "12px",
    padding: "15px 10px",
  },
  peopleCount: {
    color: "#FF691F",
  },
  redeemRewardBtn: {
    // background: "#FF712D",
    backgroundImage:
      "linear-gradient(316.81deg, #FF781F -21.21%, #FFBC70 123.06%)",
    color: "#FFF",
    borderRadius: "12px",
    padding: "8px 12px",
    maxWidth: "max-content",
    textTransform: "none",
    marginTop: "10px",
  },
  redeemRewardBox: {
    background: "linear-gradient(133.76deg, #FFF5F5 0%, #FFF5F5 98.29%)",
    border: "1px solid #FFE0E0",
    borderRadius: "12px",
    padding: "10px 12px",
  },
  rewardHeader: {
    background: "#EDF2F4",
    height: "40px",
    borderRadius: "0px 12px 0px 0px",
  },
  rewardBox: {
    width: "45%",
  },
  country: {
    background: "#F9FAFB",
    paddingLeft: "20px",
    minHeight: "64px",
    "&:nth-child(4)": {
      background: "#FFF",
    },
  },
  rewards: {
    background: "#F9FAFB",
    minHeight: "64px",
    "&:nth-child(4)": {
      background: "#FFF",
    },
  },
  regionHeader: {
    background: "#EDF2F4",
    height: "40px",
    borderRadius: "12px 0px 0px 0px",
  },
  referalRegionBox: {
    border: "1px solid #E6EFEF",
    background: "#F9FAFB",
    borderRadius: "12px",
    padding: "0px 0px 5px 0px",
  },
  regionBox: {
    width: "55%",
    borderRight: "1px solid #E6EFEF",
  },
  inviteBox: {
    border: "1px solid #E6EFEF",
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    borderRadius: "12px",
    padding: "15px",
  },
  inputField: {
    borderRadius: "4px",
    background: "#F0F4F4",
    height: "40px",
    border: "1px solid #E6EFEF",
  },

  referealInputField: {
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 500,
    background: "#ffffff",
    height: "40px",
    marginTop: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#AFCFCF",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#AFCFCF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#AFCFCF",
      },
    },
  },

  satoshiMedium: {
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 500,
  },

  satoshiBold: {
    fontFamily: "Satoshi, sans-serif",
    fontWeight: 700,
  },
  bookDemoField: {
    borderRadius: "4px",
    background: "#FFFFFF",
    height: "40px",
    border: "1px solid #E6EFEF",
  },
  textFields: {
    marginTop: 25,
  },
  activeCourseCard: {
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    border: "1px solid #E6EFEF",
    padding: "10px",
    width: "100%",
    marginTop: "12px",
    borderRadius: "12px",
  },
  CompletedCourseCard: {
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    border: "1px solid #E6EFEF",
    maxWidth: "100%",
    height: "180px",
    borderRadius: "12px",
    padding: "5px 15px",
  },
  btnClr: {
    color: "#FF712D",
  },
  width100: {
    width: "100%",
  },
  courseDetailsBox: {
    width: "70%",
  },
  progressBar: {
    width: "80%",
  },
  sprCta: {
    textTransform: "none",
    cursor: "pointer",
  },
  percentGreen: {
    marginLeft: "10px",
    color: "#4EB586",
  },
  percentDefault: {
    marginLeft: "10px",
    color: "#F58300",
  },
  stickyBtnWrapper: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    boxShadow: "0px -2px 4px 0px #0000000A",
    background: "#FFFFFF",
    padding: "16px 0px 16px 0px",
    zIndex: 2,
  },
  stickyBtn: {
    minWidth: "70%",
    maxWidth: "max-content",
    background: "#FF712D",
    color: "#FFFFFF",
    padding: "8px 20px 8px 20px",
    borderRadius: "12px",
    textTransform: "none",
    "&:hover": {
      background: "#FF712D",
      color: "#FFFFFF",
    },
  },
  cancelBtn: {
    border: "1px solid #EB5E47",
    color: "#EB5E47",
    boxShadow: "0px 1px 2px 0px #FBE2C6",
    padding: "8px 16px",
    minWidth: "100px",
    maxWidth: "max-content",
    borderRadius: "12px",
    "&:hover": {
      background: "transparent",
      color: "#EB5E47",
    },
  },
  saveBtn: {
    boxShadow: "0px 1px 2px 0px #FBE2C6",
    background: "#FF712D",
    color: "#FFF",
    padding: "8px 16px",
    minWidth: "100px",
    maxWidth: "max-content",
    borderRadius: "12px",
    textTransform: "none",
    "&:hover": {
      background: "#FF712D",
      color: "#FFF",
    },
  },
  demoCard: {
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    border: "1px solid #E6EFEF",
    borderRadius: "12px",
    padding: "10px",
  },
  textAreaBox: {
    border: "1px solid #E6EFEF",
    background: "#F0F4F4",
    width: "100%",
    borderRadius: "8px",
    padding: "10px",
  },
  carouselWidth: {
    [theme.breakpoints.up("md")]: { width: "68%" },
    [theme.breakpoints.down("md")]: { width: "90%" },
  },
  slothsWidth: {
    margin: "0 30px",
  },
  customerInfoMweb: {
    backgroundImage: `url("./customerInfoMweb.svg")`,
    height: "200px",
    width: "100%",
  },
  customerInfoDweb: {
    backgroundImage: `url("./customerInfoDweb.svg")`,
    height: "100%",
    width: "100%",
  },
  popupBackgroundBox: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 0,
    overflowY: "auto",
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popupBox: {
    backgroundColor: "#fff",
    width: "450px",
    height: "230px",
    borderRadius: "8px",

    [theme.breakpoints.down("md")]: { width: "355px" },
  },
  popupImg: {
    width: "75px",
    height: "60px",
    marginTop: "25px",
  },
  popupText: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "15px",
  },
  noBtn: {
    padding: "12px 24px",
    border: "2px solid #FF712D",
    borderRadius: "4px",
    color: "#FF712D",
    fontSize: "14px",
    fontWeight: "700",
    backgroundColor: "#fff",
    marginTop: "20px",
    marginRight: "20px",
  },
  yesBtn: {
    padding: "12px 24px",
    border: "2px solid #FF712D",
    borderRadius: "4px",
    color: "#FF712D",
    fontSize: "14px",
    fontWeight: "700",
    backgroundColor: "#fff",
    marginTop: "20px",
  },
  timeBtnActive: {
    height: "35px",
    minWidth: "100px",
    borderRadius: "8px",
    backgroundColor: "#FF712D",
    color: "#FFF",
    border: "none",
    fontSize: "14px",
    fontWeight: 500,
  },
  timeBtn: {
    height: "35px",
    minWidth: "100px",
    borderRadius: "8px",
    background: "#FFF",
    color: "#569090",
    border: "1.6px solid #CFE2E2",
    fontSize: "14px",
    fontWeight: 500,
  },
  succesMsgBox: {
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    border: "1px solid #E6EFEF",
    borderRadius: "12px",
    minHeight: "210px",
    padding: "10px",
  },
  classCancelledText: {
    color: "#EB5E47",
    fontFamily: "Roboto",
    marginTop: "3px",
  },
  rescheduleText: {
    color: "#F3873F",
    fontFamily: "Roboto",
  },
  sessionName: {
    color: "#FD8D4D",
  },
  sessionNameApp: {
    background: "linear-gradient(101.84deg, #FEF1D7 0.52%, #FEEFD7 102.48%)",
    position: "absolute",
    top: 25,
    right: 10,
    color: "#FD8D4D",
    padding: "4px 8px",
    borderRadius: "8px",
  },
  verticalDivider: {
    height: "200px",
    border: "1px solid #E6EFEF",
    color: "#E6EFEF",
    background: "#E6EFEF",
  },

  // Student app revamp styles for student summary component
  paddingVerticalZero: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },

  subtitleColor: {
    color: "#569090",
  },
  studentNameWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
  },
  title: {
    color: "#446C6C",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "700",
  },
  gradeWrapper: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "center",
  },
  grade: {
    color: "#95B2B2",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
  },

  studentPortalBtnWrapper: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "center",
  },
  studentPortalBtn: {
    backgroundImage:
      "linear-gradient(316.81deg, #FF781F -21.21%, #FFBC70 123.06%)",
    color: "#fff",
    borderRadius: "12px",
    textTransform: "capitalize",
  },
  noahWrapper: {
    padding: "20px 22px",
    backgroundImage: "linear-gradient(135.01deg, #FBF5E9 0%, #FBE9F5 100%)",
    // border: "1px solid",
    // borderImage: "linear-gradient(135.01deg, #F6E8CA , #F6CAE8 ) 0 100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "12px",
    alignItems: "center",
  },
  noahImg: {
    width: "86px",
    height: "72px",
    objectFit: "contain",
  },
  launchNoahBtn: {
    backgroundImage: "linear-gradient(180deg, #FFD361 0%, #FFC52E 100%)",
    border: "1px solid #FAB400",
    height: "39px",
    padding: "10px 36px",
    borderRadius: "12px",
    color: "#943000",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18.9px",
    textTransform: "capitalize",
  },
}));
