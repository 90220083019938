import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

const Routes = (props) => {
  const { routes, redirectTo } = props;

  const renderRoutes = () => {
    return routes.map(
      ({
        component: Component,
        path,
        active = true,
        render,
        allow,
        ...rest
      }) => {
        if (!active) return null;
        return (
          <Route
            key={path}
            path={path}
            component={Component}
            render={(props) =>
              allow === true ? <Component /> : <Redirect to={redirectTo} />
            }
            {...rest}
          />
        );
      }
    );
  };
  return (
    <Fragment>
      <Switch>
        {renderRoutes()}
        <Redirect to={redirectTo} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
